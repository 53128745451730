import React, { FC, Fragment, ReactElement } from "react";
import { Typography, TypographyProps } from "@mui/material";

interface TypographyWithHighlightProps extends TypographyProps {
    text: string;
    highlight: string | ReactElement<{ children: string }>;
    highlightProps?: TypographyProps;
}

const TypographyWithHighlight: FC<TypographyWithHighlightProps> = ({ text, highlight, highlightProps, ...typographyProps }) => {
    const isHighlightComponent = React.isValidElement(highlight);
    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    const extractStringFromNode = (): string => {
        if (isHighlightComponent && typeof highlight.props.children === "string") {
            return highlight.props.children;
        }
        return highlight as string;
    };

    const highlightString = extractStringFromNode();
    const highlightedTextRegex = new RegExp(`(${escapeRegExp(highlightString)})`, "g");

    const parts = text.split(highlightedTextRegex);

    const renderPart = (part, index) => {
        if (part !== highlightString) {
            return <Fragment key={index}>{part}</Fragment>;
        }

        return isHighlightComponent ? (
            <Fragment key={index}>{highlight}</Fragment>
        ) : (
            <Typography key={index} component="span" variant="inherit" display="inline" {...highlightProps}>
                {part}
            </Typography>
        );
    };

    return <Typography {...typographyProps}>{parts.map((part, index) => renderPart(part, index))}</Typography>;
};

export default TypographyWithHighlight;
