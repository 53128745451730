import { AppRoutes } from "enums/routes.enum";

export const HEADER_EXCLUDED_PATHS = [
    AppRoutes.SHARE_NON_LEGAL,
    AppRoutes.SHARE_LEGAL,
    AppRoutes.RESTRICTED_ACCESS,
    AppRoutes.FIND_DEVICE,
    AppRoutes.LOST_PHONE,
    AppRoutes.OAUTH,
];
