import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let ApplePayTestPage = lazy(() => import("./ApplePayTestPage"));

export async function lazyApplePayTestPageLoader() {
    const componentModule = await import("./ApplePayTestPage");
    // This avoid flicker from React.lazy by using the component directly
    ApplePayTestPage = componentModule.default as any;

    return null;
}

export function LazyApplePayTestPage() {
    return (
        <Suspense fallback={<PageFallback />}>
            <ApplePayTestPage />
        </Suspense>
    );
}
