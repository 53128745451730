import { action, makeObservable, observable } from "mobx";
import { CountryCode } from "libphonenumber-js";
import { CarrierData, getPhoneCarrier } from "http-client/phone.client";
import { RootStore } from "./root.store";
import { loadFromStorage, saveToStorage } from "services/storage";

interface IpInfo {
    ip: string;
    city: string;
    country: CountryCode;
    postal: string;
    loc: string;
    timezone: string;
}

export class AppStore {
    rootStore: RootStore;
    amplitudeUserId = localStorage.getItem("amplitudeUserId") || "";
    searchPhoneNumber = sessionStorage.getItem("searchPhoneNumber") || "";
    amplitudeDeviceId = localStorage.getItem("deviceId") || "";
    searchCountryCode = "";
    searchCarrierData: CarrierData | null = null;
    ipInfo: IpInfo | null = null;
    abTestData: Record<string, any> | null = loadFromStorage("abTestData") ?? null;
    isAccountRedirectDisabled = true;
    isPaymentMapFormShown = false;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    flowOuter = localStorage.getItem("flowOuter") ? JSON.parse(localStorage.getItem("flowOuter")!) : false;
    loading = false;
    ampliIDLoading = true;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            ipInfo: observable,
            searchPhoneNumber: observable,
            amplitudeDeviceId: observable,
            searchCarrierData: observable,
            amplitudeUserId: observable,
            loading: observable,
            ampliIDLoading: observable,
            isAccountRedirectDisabled: observable,
            isPaymentMapFormShown: observable,
            abTestData: observable,
            flowOuter: observable,
            setIpInfo: action,
            setSearchPhoneNumber: action,
            setAmplitudeDeviceId: action,
            setPhoneCarrier: action,
            setAmplitudeUserId: action,
            setLoading: action,
            setAmpliIDLoading: action,
            setFlowOuter: action,
            setAbTestData: action,
            setIsPaymentMapFormShown: action,
        });
        this.rootStore = rootStore;
    }

    setIpInfo = (ipInfo: any) => {
        this.ipInfo = ipInfo;
    };

    setIsAccountRedirectDisabled = (isDisabled: boolean) => {
        this.isAccountRedirectDisabled = isDisabled;
    };

    setSearchPhoneNumber = (searchPhoneNumber: string) => {
        sessionStorage.setItem("searchPhoneNumber", searchPhoneNumber);
        this.searchPhoneNumber = searchPhoneNumber;
    };

    setAmplitudeDeviceId = (deviceId: string) => {
        localStorage.setItem("deviceId", deviceId);
        this.amplitudeDeviceId = deviceId;
    };

    setSearchCountryCode = (searchCountryCode: string) => {
        this.searchCountryCode = searchCountryCode;
    };

    setPhoneCarrier = (carrierData: CarrierData) => {
        this.searchCarrierData = carrierData;
    };

    fetchPhoneCarrier = async () => {
        const data = await getPhoneCarrier(this.searchPhoneNumber);
        if (data) {
            this.setPhoneCarrier(data);
        }
        return data;
    };

    setAbTestData = (abTestData: Record<string, any> | null) => {
        saveToStorage("abTestData", JSON.stringify(abTestData));
        this.abTestData = abTestData;
    };

    setLoading = (loading: boolean) => {
        this.loading = loading;
    };

    setAmpliIDLoading = (loading: boolean) => {
        this.ampliIDLoading = loading;
    };

    setFlowOuter = (flowOuter: boolean) => {
        this.flowOuter = flowOuter;
        localStorage.setItem("flowOuter", JSON.stringify(flowOuter));
    };

    setAmplitudeUserId = (userId: string) => {
        localStorage.setItem("amplitudeUserId", userId);
        this.amplitudeUserId = userId;
    };

    setIsPaymentMapFormShown = (isShown: boolean) => {
        this.isPaymentMapFormShown = isShown;
    };

    hydrate = (data: any) => {
        if (!data) {
            return;
        }
        if (data.searchPhoneNumber) {
            this.searchPhoneNumber = data.searchPhoneNumber;
        }
    };
}
