import { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

import { ReactComponent as CloseIcon } from "assets/icons/close-lightgrey.svg";

const CloseButton: FC<SvgIconProps> = (props) => (
    <SvgIcon
        sx={(theme) => ({
            position: "absolute",
            top: "16px",
            right: "16px",
            "& svg path": {
                fill: theme.palette.text.tertiary,
            },
            cursor: "pointer",
        })}
        {...props}
    >
        <CloseIcon />
    </SvgIcon>
);

export default CloseButton;
