import { Button, Typography } from "@mui/material";
import { ContactUsPopup } from "components/ContactUsPopup";
import { useAccountFeature } from "pages/Account/context/Feature.context";
import { useTranslation } from "react-i18next";

export const AccountContactUs = () => {
    const { t } = useTranslation();
    const { featureKey } = useAccountFeature();

    return (
        <ContactUsPopup
            feature={featureKey}
            popupTrigger={
                <Button
                    variant="xs"
                    sx={(theme) => ({
                        width: "100%",
                        textTransform: "capitalize",
                        background: "none",
                        border: `1px solid ${theme.palette.background.whiteOpacity4}`,
                        minHeight: "40px",
                        mb: { xs: undefined, lmd: "16px" },
                    })}
                >
                    <Typography variant="body2Regular">{t("Contact Us.").replace(".", "")}</Typography>
                </Button>
            }
            image="/images/contact-top-imagea.png"
        />
    );
};
