import z from "zod";

export const schema = z.object({
    name: z.string().min(1, ""),
    email: z.string().min(1, "").email("Email_error"),
    subject: z.string().min(1, ""),
    message: z.string().min(1, ""),
});

export type FormData = z.infer<typeof schema>;
