import { forwardRef } from "react";
import { styled, TextField, TextFieldProps } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { themeFonts } from "mui-theme/themeFonts.const";

export type InputTextFieldProps = TextFieldProps & { placeholderfontsize?: string };

export const StyledTextInput = styled(TextField)<InputTextFieldProps>((props) => ({
    "& .MuiInputBase-root": {
        borderRadius: "1rem",
        border: `2px solid ${themeColors.background.whiteOpacity4}`,
        backgroundColor: themeColors.background.secondary,
        "&.Mui-focused": {
            borderColor: themeColors.brandColors.brandGreen,
            "& input": {
                borderColor: themeColors.brandColors.brandGreen,
                caretColor: themeColors.brandColors.brandGreen,
            },
        },
        "&.Mui-error": {
            borderColor: themeColors.state.error,
            color: themeColors.state.error,
            "& input": {
                color: themeColors.state.error,
                caretColor: themeColors.state.error,
            },
        },
        "&.Mui-focused:not(.Mui-focused.Mui-error)": {
            borderColor: themeColors.brandColors.brandGreen,
        },
        "& fieldset": {
            borderColor: "transparent !important",
        },
        "& ::placeholder": {
            opacity: 1,
            fontFamily: themeFonts.sourceCodePro,
            fontSize: props.placeholderfontsize || "20px",
            fontWeight: 500,
            color: themeColors.text.grey,
        },
    },
    "& input": {
        padding: "1.25rem 1.5rem",
        color: themeColors.text.white,
        fontWeight: 500,
        fontSize: "20px",
        fontFamily: themeFonts.sourceCodePro,
        background: themeColors.background.secondary,
        borderRadius: "1rem",
        height: "63px",
        maxHeight: "63px",
        boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${themeColors.background.secondary} inset`,
        WebkitTextFillColor: themeColors.text.white,
    },
    "& .MuiFormHelperText-root": {
        fontFamily: themeFonts.sourceCodePro,
        fontSize: "1rem",
        lineHeight: "1.25rem",
        whiteSpace: "pre-line",
    },
}));

const StyledLightTextInput = styled(StyledTextInput)<InputTextFieldProps>(({ theme }) => ({
    "& .MuiInputBase-root": {
        backgroundColor: themeColors.background.white,
        border: `2px solid ${themeColors.text.lightgrey}`,
    },
    "& input": {
        background: themeColors.background.white,
        color: themeColors.text.black,
        padding: "20px 24px",
        height: "60px",
    },
    "& .MuiOutlinedInput-input:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${themeColors.background.primary} inset`,
        WebkitTextFillColor: themeColors.text.black,
    },
    "& .MuiInputLabel-root": {
        position: "static",
        fontFamily: themeFonts.salara,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
        transform: "unset",
        marginBottom: "2px",
        color: themeColors.text.black,

        "&.Mui-focused": {
            color: themeColors.brandColors.brandGreen,
        },
    },
    [theme.breakpoints.down("lmd")]: {
        "& .MuiInputLabel-root": {
            fontSize: "12px",
        },
    },
}));

export const InputTextField: React.FC<InputTextFieldProps> = (props) => <StyledTextInput {...props} />;

export const InputTextFieldLight = forwardRef<any, InputTextFieldProps>((props, ref) => (
    <StyledLightTextInput
        inputRef={ref}
        InputLabelProps={{
            shrink: true,
            disableAnimation: true,
        }}
        {...props}
    />
));

InputTextFieldLight.displayName = "InputTextFieldLight";
