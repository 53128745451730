import { CSSObject, Stack, styled, Typography } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { FC, Fragment, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";
import { useAppData } from "providers/RootStoreProvider";
import { useTranslation } from "hooks/useTranslation";
import { ContactUsPopup } from "components/ContactUsPopup";
import { AppRoutes } from "enums/routes.enum";

interface NavMenuProps {
    menu?: Record<string, any>[];
    children?: ReactNode;
    ulStyle?: CSSObject;
    liStyle?: CSSObject;
    subitemStyle?: CSSObject;
    landingType?: string;
}

const StyledNavMenu = styled("ul")<NavMenuProps>(({ ulStyle }) => ({
    display: "flex",
    alignItems: "stretch",
    background: themeColors.background.primary,
    borderRadius: "10px",
    border: "1px solid rgba(45, 49, 55, 0.05)",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    width: "fit-content",
    marginTop: "40px",
    ...ulStyle,
}));

const StyledNavMenuItem = styled("li")<NavMenuProps>(({ liStyle }) => ({
    padding: "8px 24px",
    position: "relative",
    "&:hover div": {
        visibility: "visible",
        zIndex: 10,
    },
    "&:hover img": {
        transform: "rotate(180deg)",
    },
    ...liStyle,
}));

const StyledNavItemDropdownWrap = styled(Stack)({
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 100%)",
    width: "fit-content",
    padding: "14px 27px 24px",
    background: "#141414",
    alignItems: "center",
    visibility: "hidden",
    zIndex: -1,
});

export const HeaderNavMenu: FC<NavMenuProps> = ({ ulStyle, liStyle, menu, landingType }) => {
    const { t, detect } = useTranslation();
    const { pathname } = useLocation();
    const { flowOuter, abTestData } = useAppData();
    const abTestContact = abTestData?.["abTestContact"];
    const isAbTestContact = abTestContact === "1";
    const showContactsPopup = isAbTestContact && flowOuter;

    const smallerItemPadding = detect.isFrenchLanguage || detect.isSpanishLanguage || detect.isVietnameseLanguage || detect.isGermanLanguage;

    const handleNavItemClick = (e, item: Record<string, any>) => {
        if (item.children?.length) {
            e.preventDefault();
        }

        handleAmpliLoaded(() => ampli.landingMenuClicked({ chosen_navigation_item: item.title, landing_type: landingType ?? "locate by number legal" }));
    };

    return (
        <>
            <StyledNavMenu ulStyle={ulStyle}>
                {menu?.map((item) => {
                    if (!showContactsPopup || (showContactsPopup && item.title !== "Contacts"))
                        return (
                            <Fragment key={item.id}>
                                <StyledNavMenuItem liStyle={{ padding: smallerItemPadding ? "8px 12px" : "8px 24px", ...liStyle }}>
                                    <Link
                                        to={item.href}
                                        onClick={(e) => handleNavItemClick(e, item)}
                                        style={{ display: "flex", alignItems: "center", gap: "3px" }}
                                    >
                                        <Typography variant="body2Semibold" fontSize={detect.isVietnameseLanguage ? "14px" : undefined}>
                                            {t(item.title)}
                                        </Typography>
                                        {item.children && <img src="/icons/arrow.svg" style={{ transition: ".2s" }} alt={item.title} />}
                                    </Link>

                                    {item.children && flowOuter && (
                                        <StyledNavItemDropdownWrap>
                                            {item.children.map((child) => (
                                                <Typography
                                                    variant="body2Regular"
                                                    key={`item-child${item.id}-${child.title}`}
                                                    whiteSpace="nowrap"
                                                    minWidth="300px"
                                                    textAlign="center"
                                                    borderTop=".5px solid transparent"
                                                    borderLeft=".5px solid transparent"
                                                    borderRight=".5px solid transparent"
                                                    borderBottom={`.5px solid ${themeColors.background.whiteOpacity4}`}
                                                    color={themeColors.text.grey}
                                                    sx={{
                                                        transition: ".2s",
                                                        borderRadius: "4px",
                                                        lineHeight: "24px",
                                                        background:
                                                            child.href === pathname
                                                                ? "linear-gradient(45deg, rgba(94, 130, 246, 0.2) -0.09%, rgba(134, 94, 246, 0.2) 99.91%)"
                                                                : undefined,
                                                        "&:not(:last-of-type)": {
                                                            mb: "24px",
                                                        },
                                                        "&:hover": {
                                                            color: themeColors.background.primary,
                                                            fontSize: "17px",
                                                        },
                                                    }}
                                                >
                                                    <Link
                                                        to={child.href}
                                                        onClick={(e) => handleNavItemClick(e, child)}
                                                        style={{
                                                            lineHeight: "24px",
                                                        }}
                                                    >
                                                        {t(child.title)}
                                                    </Link>
                                                </Typography>
                                            ))}
                                        </StyledNavItemDropdownWrap>
                                    )}
                                </StyledNavMenuItem>
                            </Fragment>
                        );
                })}
                <ContactUsPopup
                    feature={pathname === AppRoutes.HOME ? "location by number" : "lost my phone"}
                    popupTrigger={
                        <StyledNavMenuItem liStyle={liStyle} sx={{ cursor: "pointer", padding: smallerItemPadding ? "8px 12px" : "8px 24px" }}>
                            <Typography variant="body2Semibold" fontSize={detect.isVietnameseLanguage ? "14px" : undefined}>
                                {t("Contact Us.").replace(".", "")}
                            </Typography>
                        </StyledNavMenuItem>
                    }
                    className="noGradiend"
                    sx={{
                        "&.noGradiend .MuiPaper-root": {
                            pt: { xs: "44px", lmd: "36px" },
                            background: themeColors.brandColors.brandWhite,
                        },
                    }}
                />
            </StyledNavMenu>
        </>
    );
};
