import { styled } from "@mui/material";
import { InputTextFieldLight } from "components/shared";

export const TextField = styled(InputTextFieldLight)(({ theme }) => ({
    "& .MuiInputBase-root": {
        backgroundColor: `${theme.palette.background.lightAccent} !important`,
        border: `1px solid ${theme.palette.brandColors.brandLightgrey}`,
        "& input": {
            maxHeight: "100%",
            backgroundColor: "transparent !important",
        },
    },
    "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
        color: `${theme.palette.text.primary} !important`,
    },
}));
