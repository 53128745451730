import { Dialog, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    backdropFilter: "blur(2px)",
    padding: 0,
    ".MuiDialogTitle-root": {
        padding: 0,
    },
    ".MuiDialogContent-root": {
        padding: 0,
        overflow: "unset",
    },

    ".MuiDialog-paper": {
        width: "100%",
        borderRadius: "24px",
        zIndex: 1,
        margin: "0",
        maxWidth: "600px",
        background: `linear-gradient(270deg, rgba(125, 205, 214, 0.3) 0%, rgba(255, 253, 235, 0.3) 100%), ${theme.palette.brandColors.brandWhite}`,
    },
    [theme.breakpoints.down("lmd")]: {
        ".MuiDialog-paper": {
            borderRadius: "48px 48px 0px 0px",
            maxWidth: "none",
            "&::before": {
                borderRadius: "48px 48px 0px 0px",
            },
        },
        ".MuiDialog-container": {
            alignItems: "end",
        },
    },
}));
