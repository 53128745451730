import { Box, BoxProps, styled } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface Props extends BoxProps {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
}

const StyledContainer = styled(Box)<Props>(({ theme }) => ({
    width: "86%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
        width: "calc(100% - 40px)",
    },
}));

export const WidthContainer = ({ children, className, style, ...boxProps }: Props) => (
    <StyledContainer style={style} className={className} {...boxProps}>
        {children}
    </StyledContainer>
);
