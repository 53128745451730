import { forwardRef } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useBoolean } from "hooks/useBoolean";
import { InputTextFieldLight, InputTextFieldProps } from "./InputTextField";

export type PasswordFieldProps = Omit<InputTextFieldProps, "type" | "InputProps">;

const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(({ onChange, ...props }, ref) => {
    const [showPassword, setShowPassword] = useBoolean();

    return (
        <InputTextFieldLight
            ref={ref}
            {...props}
            onChange={(e) => {
                e.target.value = e.target.value.trim();
                onChange?.(e);
            }}
            type={showPassword ? "text" : "password"}
            autoComplete="new-password"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: "8px" }}>
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            sx={(theme) => ({ color: theme.palette.text.disabled })}
                            onClick={setShowPassword.toggle}
                        >
                            {showPassword ? <VisibilityOutlined color="inherit" /> : <VisibilityOffOutlined color="inherit" />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
});

export default PasswordField;

PasswordField.displayName = "PasswordField";
