import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLogout } from "hooks/useLogout";

const LogoutButton = () => {
    const { t } = useTranslation();
    const logout = useLogout();

    return (
        <Button
            variant="xs"
            sx={(theme) => ({
                width: "100%",
                textTransform: "capitalize",
                background: "none",
                border: `1px solid ${theme.palette.background.whiteOpacity4}`,
                minHeight: "40px",
            })}
            onClick={logout}
        >
            <Typography variant="body2Regular">{t("Log out")}</Typography>
        </Button>
    );
};

export default LogoutButton;
