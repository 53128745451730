import { Stack, styled, Typography } from "@mui/material";
import { AppRoutes } from "enums/routes.enum";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { NAV_MENU_NON_LEGAL_MOBILE } from "./navMenu.const";
import { themeColors } from "constants/colors.const";
import { useSidebarLogic } from "hooks/useSidebarLogic";
import LanguageAccordion from "../shared/LanguageAccordion";
import { ContactUsPopup } from "components/ContactUsPopup";

const StyledNavMenuLink = styled(Link)({
    borderBottom: "1px solid #F3F4F7",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const StyledNavMenuSublink = styled(Link)({
    width: "80%",
    margin: "0 auto 24px",
    borderTop: ".5px solid transparent",
    borderLeft: ".5px solid transparent",
    borderRight: ".5px solid transparent",
    borderBottom: `.5px solid ${themeColors.background.whiteOpacity4}`,
    textAlign: "center",
    borderRadius: "4px",
    transition: ".3s",
    height: "32px",
    "&.active": {
        background: "linear-gradient(45deg, rgba(94, 130, 246, 0.2) -0.09%, rgba(134, 94, 246, 0.2) 99.91%)",
    },
});

interface Props {
    onClose: () => void;
}

export const NonLegalMenuContent: FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const isLostPhonePage = pathname === AppRoutes.LOST_PHONE;
    const { handleLanguagePick, handleMenuItemClick } = useSidebarLogic({
        onClose,
        landing_type: isLostPhonePage ? "lost my phone" : "locate by number non legal",
    });
    const [navItems, setNavItems] = useState<Record<string, any>>(NAV_MENU_NON_LEGAL_MOBILE);

    const isAccountPage = pathname === AppRoutes.ACCOUNT;
    const isSearchPage = pathname === AppRoutes.SEARCH;
    const showNavigation = !isAccountPage && !isSearchPage;

    const handleNavClick = (e, item: Record<string, any>) => {
        if (item.children) {
            e.preventDefault();
            setNavItems((prevItems) => {
                return prevItems.map((navItem) => {
                    if (navItem.id === item.id) {
                        return {
                            ...navItem,
                            open: !navItem.open,
                        };
                    }
                    return navItem;
                });
            });
        } else {
            handleMenuItemClick(item.title);
        }
    };

    return (
        <>
            <Stack>
                {showNavigation &&
                    navItems.map((item, index) => (
                        <Fragment key={index}>
                            <StyledNavMenuLink
                                key={item.id}
                                to={item.href}
                                sx={{
                                    borderBottom: item.children ? "1px solid #F3F4F7" : ".5px solid #F3F4F7",
                                    mb: item.open ? "8px" : "24px",
                                }}
                                onClick={(e) => handleNavClick(e, item)}
                            >
                                <Typography variant="body2Regular" color={themeColors.text.white} sx={{ height: "28px" }}>
                                    {t(item.title)}
                                </Typography>
                                {item.children && (
                                    <img src="/icons/arrow.svg" alt="extend list" style={{ transform: item.open ? "rotate(180deg)" : "unset" }} />
                                )}
                            </StyledNavMenuLink>
                            {item.open &&
                                item.children?.map((child, index) => (
                                    <StyledNavMenuSublink
                                        to={child.href}
                                        key={`nav-child-${index}`}
                                        onClick={() => handleMenuItemClick(child.title)}
                                        className={child.href === pathname ? "active" : undefined}
                                    >
                                        <Typography
                                            variant="body2Regular"
                                            color={child.href === pathname ? themeColors.text.white : themeColors.text.grey}
                                            lineHeight="32px"
                                        >
                                            {t(child.title)}
                                        </Typography>
                                    </StyledNavMenuSublink>
                                ))}
                        </Fragment>
                    ))}
                <ContactUsPopup
                    feature={pathname === AppRoutes.HOME ? "location by number" : "lost my phone"}
                    popupTrigger={
                        <StyledNavMenuLink
                            to=""
                            sx={{
                                borderBottom: ".5px solid #F3F4F7",
                                mb: "24px",
                            }}
                        >
                            <Typography variant="body2Regular" color={themeColors.text.white} sx={{ height: "28px" }}>
                                {t("Contact Us.").replace(".", "")}
                            </Typography>
                        </StyledNavMenuLink>
                    }
                    className="noGradiend"
                    sx={{
                        "&.noGradiend .MuiPaper-root": {
                            pt: { xs: "44px", lmd: "92px" },
                            background: themeColors.brandColors.brandWhite,
                        },
                    }}
                />
            </Stack>
            <LanguageAccordion onSelect={handleLanguagePick} />
        </>
    );
};

NonLegalMenuContent.displayName = "NonLegalMenuContent";
