import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";

let LostPhone = lazy(() => import("./LostPhone"));

export async function lazyLostPhoneLoader() {
    const componentModule = await import("./LostPhone");
    // This avoid flicker from React.lazy by using the component directly
    LostPhone = componentModule.default as any;

    return null;
}

export function LazyLostPhone() {
    const { t } = useTranslation();

    return (
        <Suspense fallback={t("loading")}>
            <LostPhone />
        </Suspense>
    );
}
