import dayjs from "dayjs";

export const getDayOfYear = () => {
    const today = new Date().getTime();
    const firstDayOfYear = new Date(new Date().getFullYear(), 0, 0).getTime();
    return Math.floor((today - firstDayOfYear) / 1000 / 60 / 60 / 24);
};

export const getMonthOfYear = () => {
    return new Date().getMonth() + 1;
};

export const getWeekOfYear = () => {
    const now = new Date();
    const dateNow = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
    const dayNum = dateNow.getUTCDay() || 7;
    dateNow.setUTCDate(dateNow.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(dateNow.getUTCFullYear(), 0, 1));
    return Math.ceil(((dateNow.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
};

export const getYear = () => {
    return new Date().getFullYear();
};

export const getTomorrowDate = (isUSAUser?: boolean) => {
    const today = dayjs();
    const tomorrow = today.add(1, "day");
    const formattedDate = tomorrow.format(isUSAUser ? "MM.DD.YYYY" : "DD.MM.YYYY");

    return formattedDate;
};
