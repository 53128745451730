import { TextareaAutosize, styled } from "@mui/material";

export const TextArea = styled(TextareaAutosize)(({ theme }) => ({
    border: `1px solid ${theme.palette.brandColors.brandLightgrey}`,
    borderRadius: "1rem",
    padding: "1rem 1.5rem",
    fontSize: "20px",
    fontFamily: theme.typography.dmSansH3Semibold.fontFamily,
    backgroundColor: theme.palette.background.lightAccent,
    outline: "none",
    "&::placeholder": {
        color: theme.palette.text.disabled,
    },
    "&:focus-visible, &:focus": {
        border: `1px solid ${theme.palette.brandColors.brandGreen}`,
    },
}));
