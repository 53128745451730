export enum LandingType {
    LOST_PHONE = "lost_phone",
    LOCATE_NUMBER = "locate_number",
    DEFAULT = "default",
}

export enum LandingVariant {
    FAQ_V2 = "faq_2.0",
    WITH_FAQ = "new_with_faq",
}

export enum LandingMode {
    LOCATE_NUMBER_MODE = "locate by number",
    LOST_MY_PHONE_MODE = "lost my phone",
}
