import { ChangeEvent, forwardRef, ReactElement } from "react";
import { MenuItem as MUIMenuItem, Typography } from "@mui/material";

import AutocompleteTextField, { TSuggestionRenderFn } from "components/shared/AutocompleteTextField";
import { InputTextFieldProps } from "./InputTextField";

const emailProviders = [
    "@gmail.com",
    "@yahoo.com",
    "@hotmail.com",
    "@aol.com",
    "@hotmail.co.uk",
    "@hotmail.fr",
    "@msn.com",
    "@yahoo.fr",
    "@wanadoo.fr",
    "@orange.fr",
];

const EmailAutocompleteTextField = forwardRef<HTMLInputElement, InputTextFieldProps & { value: string }>((props, ref): ReactElement => {
    const { value, onChange } = props;
    const isPopperOpen = (value || "").length >= 2 && emailProviders && emailProviders.length > 0 && !value.includes("@");

    const suggestionRenderCustomFn: TSuggestionRenderFn = (_idx, suggestion, onSelectSuggestion) => {
        return (
            <MUIMenuItem key={suggestion} selected={false} onClick={onSelectSuggestion?.(suggestion)}>
                <Typography component="div" variant="body1">
                    {`${value}${suggestion}`}
                </Typography>
            </MUIMenuItem>
        );
    };

    const handleSelectSuggestion = (suggestionSelected: any) => {
        return async () => {
            const valuePrefix = (value || "").split("@")?.[0] || "";

            const event = {
                target: {
                    value: `${valuePrefix}${suggestionSelected}`,
                },
            };

            onChange?.(event as ChangeEvent<HTMLInputElement>);
        };
    };

    return (
        <AutocompleteTextField
            ref={ref}
            suggestions={emailProviders}
            suggestionRenderCustomFn={suggestionRenderCustomFn}
            handleSelectSuggestion={handleSelectSuggestion}
            isPopperOpen={isPopperOpen}
            {...props}
        />
    );
});

export default EmailAutocompleteTextField;

EmailAutocompleteTextField.displayName = "EmailAutocompleteTextField";
