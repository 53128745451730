const fontFamilySalara = ["Sarala", "Arial", "sans-serif"].join(",");
const fontFamilySourceCodePro = ["Source code pro", "Arial", "sans-serif"].join(",");
const fontFamilyGeologica = ["Geologica", "Arial", "sans-serif"].join(",");
const fontFamilyInter = ["Inter", "Arial", "sans-serif"].join(",");
const fontFamilyDmSans = ["DM Sans", "Arial", "sans-serif"].join(",");

export const themeFonts = {
    salara: fontFamilySalara,
    sourceCodePro: fontFamilySourceCodePro,
    geologica: fontFamilyGeologica,
    inter: fontFamilyInter,
    dmSans: fontFamilyDmSans,
};
