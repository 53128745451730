import React, { forwardRef, useRef } from "react";
import { Box, Fade, MenuItem as MUIMenuItem, MenuList as MUIMenuList, Paper, Popper, Stack, Typography } from "@mui/material";
import type { AutocompleteTextFieldProps, TSuggestionRenderFn } from "./types";
import { InputTextFieldLight } from "../InputTextField";

const AutocompleteTextField = forwardRef<any, AutocompleteTextFieldProps>(
    (
        { suggestions, suggestionRenderCustomFn, handleSelectSuggestion, type = "text", variant = "outlined", isPopperOpen = false, InputProps = {}, ...props },
        ref
    ) => {
        const anchorElRef = useRef<Element>(null);

        const onKeyPress = !props.multiline
            ? (e: React.KeyboardEvent<HTMLDivElement>) => {
                  e.key === "Enter" && e.preventDefault();
              }
            : undefined;

        const suggestionRenderDefaultFn: TSuggestionRenderFn = (idx, suggestion, handleSelectSuggestion) => {
            return (
                <MUIMenuItem key={idx} selected={false} onClick={handleSelectSuggestion?.(suggestion)}>
                    <Typography component="div" className="truncated-one-line" variant="body1">
                        {JSON.stringify(suggestion)}
                    </Typography>
                </MUIMenuItem>
            );
        };

        const suggestionRenderFn = suggestionRenderCustomFn || suggestionRenderDefaultFn;

        return (
            <Stack direction="column" gap={0.5}>
                <InputTextFieldLight
                    ref={ref}
                    inputRef={anchorElRef}
                    type={type}
                    variant={variant as "outlined"}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        onKeyPress,
                        ...InputProps,
                    }}
                    fullWidth={true}
                    {...props}
                />
                <Popper
                    className="app-popper popper--places-autocomplete"
                    open={isPopperOpen}
                    anchorEl={anchorElRef.current as HTMLElement}
                    autoFocus={false}
                    transition={true}
                    keepMounted={false}
                    disablePortal={true}
                    placement="bottom-end"
                >
                    {({ TransitionProps, placement }) => (
                        <Fade {...TransitionProps} timeout={100} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                            <Box className="box--places-autocomplete-lock">
                                <Paper elevation={0} className="app-popper-paper paper--places-autocomplete">
                                    <MUIMenuList autoFocusItem={false}>
                                        {(suggestions || []).map((suggestion: any, idx: number) => suggestionRenderFn(idx, suggestion, handleSelectSuggestion))}
                                    </MUIMenuList>
                                </Paper>
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </Stack>
        );
    }
);

export default AutocompleteTextField;

AutocompleteTextField.displayName = "AutocompleteTextField";
