import { createContext, useContext } from "react";
import { FeatureProps } from "./Feature.types";

export const FeatureContext = createContext<FeatureProps | null>(null);

export const useAccountFeature = () => {
    const data = useContext(FeatureContext);

    if (!data) {
        throw new Error("Can not `useAccountFeature` outside of the `FeatureProvider`");
    }

    return data;
};
