import { Chip, ChipProps, Theme } from "@mui/material";
import { FC, useCallback } from "react";
import { LocationStatus } from "interfaces/location/location.interface";
import { useTranslation } from "react-i18next";

interface LocationStatusProps extends ChipProps {
    status: LocationStatus;
}

const LocationStatusChip: FC<LocationStatusProps> = ({ status, sx, ...chipProps }) => {
    const { t } = useTranslation();

    const getSettingsByStatus = useCallback(
        (status: LocationStatus) => {
            switch (status) {
                case LocationStatus.PENDING:
                case LocationStatus.SENT: {
                    return {
                        label: t("Pending"),
                        sx: (theme: Theme) => ({
                            background: theme.palette.text.tertiary,
                            ...sx,
                        }),
                    };
                }
                case LocationStatus.API_LOCATED: {
                    return {
                        label: t("IP Located"),
                        sx: (theme: Theme) => ({
                            background: theme.palette.brandColors.brandGreen,
                            ...sx,
                        }),
                    };
                }
                case LocationStatus.GPS_LOCATED: {
                    return {
                        label: t("GPS Located"),
                        sx: (theme: Theme) => ({
                            background: `linear-gradient(267deg, ${theme.palette.brandColors.brandGreen} 0%, #264C3A 100%);`,
                            ...sx,
                        }),
                    };
                }
                default: {
                    return {
                        label: "",
                        sx: () => ({ ...sx }),
                    };
                }
            }
        },
        [t, sx]
    );

    const settings = getSettingsByStatus(status);

    return (
        <Chip
            label={settings.label}
            size="small"
            sx={(theme) => ({
                height: "24px",
                borderRadius: "6px",
                padding: "4px 8px",
                width: "fit-content",
                ...theme.typography.dmSansBody2Semibold,
                fontWeight: 700,
                fontSize: "12px",
                color: theme.palette.text.secondary,
                "& .MuiChip-label": {
                    padding: "0",
                },
                ...settings.sx(theme),
            })}
            {...chipProps}
        />
    );
};

export default LocationStatusChip;
