import { MouseEvent, useState } from "react";
import { Button, Menu, styled } from "@mui/material";
import { LogoutButton } from "components/shared";

import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { AccountContactUs } from "./AccountContactUs";

const StyledLanguagePicker = styled(Menu)(({ theme }) => ({
    "& .MuiMenu-paper": {
        borderRadius: "10px",
        padding: "14px 10px 24px",
        background: theme.palette.background.secondary,
        maxWidth: "144px",
        width: "100%",
    },
    "& .MuiMenu-list": {
        padding: 0,
    },
}));

export const ProfileButton = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = !!anchorEl;

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <Button
                variant="outlined"
                sx={(theme) => ({
                    padding: 0,
                    width: "44px",
                    height: "44px",
                    borderRadius: "10px",
                    minWidth: "44px",
                    fontWeight: 700,
                    color: open ? theme.palette.brandColors.brandGreen : theme.palette.text.secondary,
                    backgroundColor: open ? theme.palette.text.secondary : "none",
                })}
                onClick={handleClick}
            >
                <ProfileIcon />
            </Button>
            <StyledLanguagePicker
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <AccountContactUs />
                <LogoutButton />
            </StyledLanguagePicker>
        </>
    );
};

export default ProfileButton;
