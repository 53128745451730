import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { menuList } from "./menuList.const";
import { FeatureLinksContainer } from "./Menu.styled";

const linkProps = {
    sm: {
        typography: {
            variant: "dmSansBody2Regular",
            fontSize: "12px",
            width: "70px",
        },
        icon: {},
    },
    md: {
        typography: {
            variant: "dmSansBody2Regular",
            width: "80px",
        },
        icon: {
            fontSize: "large",
        },
    },
} as const;

const FeatureLinks: FC<Partial<Record<"variant", "sm" | "md">>> = ({ variant = "md" }) => {
    const { t } = useTranslation();
    const feature = linkProps[variant];

    return (
        <FeatureLinksContainer>
            {menuList.map(({ title, path, icon }) => (
                <NavLink
                    key={path}
                    to={path}
                    style={({ isActive }) => ({
                        pointerEvents: isActive ? "none" : "initial",
                    })}
                >
                    {({ isActive }) => (
                        <Stack direction="row" alignItems="center" gap="8px">
                            <SvgIcon
                                {...feature.icon}
                                sx={(theme) => ({
                                    color: isActive ? "#865EF6" : theme.palette.brandColors.brandLightgrey,
                                })}
                            >
                                {icon}
                            </SvgIcon>
                            <Typography {...feature.typography} align="center" color={isActive ? "text.secondary" : "text.disabled"}>
                                {t(title)}
                            </Typography>
                        </Stack>
                    )}
                </NavLink>
            ))}
        </FeatureLinksContainer>
    );
};

export default FeatureLinks;
