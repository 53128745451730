import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let Oauth = lazy(() => import("./OauthPage"));

export async function lazyDeclinePaymentLoader() {
    const componentModule = await import("./OauthPage");
    // This avoid flicker from React.lazy by using the component directly
    Oauth = componentModule.default as any;

    return null;
}

export function LazyOauthPage() {
    return (
        <Suspense fallback={<PageFallback />}>
            <Oauth />
        </Suspense>
    );
}
