import { Select as MUISelect, styled } from "@mui/material";

export const Select = styled(MUISelect)(({ theme }) => ({
    backgroundColor: `${theme.palette.background.lightAccent} !important`,
    borderRadius: "1rem",
    marginBottom: "1rem",
    height: "60px",
    "& .MuiSelect-outlined": {
        padding: "1rem 1.5rem",
    },
    "& .MuiSelect-select": {
        fontSize: "20px",
        fontFamily: theme.typography.dmSansH3Semibold.fontFamily,
        fontWeight: 500,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.brandColors.brandGreen} !important`,
    },
    "& fieldset": {
        border: `1px solid ${theme.palette.brandColors.brandLightgrey}`,
    },
    "&:hover fieldset": {
        border: `1px solid ${theme.palette.brandColors.brandLightgrey} !important`,
    },
    "& .Mui-error fieldset": {
        borderColor: `${theme.palette.text.error} !important`,
    },
}));
