import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";

let FindDevice = lazy(() => import("./FindDevice"));

export async function lazyFindDeviceLoader() {
    const componentModule = await import("./FindDevice");
    // This avoid flicker from React.lazy by using the component directly
    FindDevice = componentModule.default as any;

    return null;
}

export function LazyFindDevice() {
    const { t } = useTranslation();

    return (
        <Suspense fallback={t("loading")}>
            <FindDevice />
        </Suspense>
    );
}
