import React, { FC } from "react";
import { DialogContent, DialogProps, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { StyledDialog } from "./SlideInPopup.styled";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface SlideInPopupProps extends DialogProps {
    titleComponent?: React.ReactNode;
    contentComponent?: React.ReactNode;
}

const SlideInPopup: FC<SlideInPopupProps> = ({ titleComponent, contentComponent, children, ...dialogProps }) => {
    return (
        <StyledDialog TransitionComponent={Transition} {...dialogProps}>
            {titleComponent && <DialogTitle>{titleComponent}</DialogTitle>}
            {contentComponent && <DialogContent>{contentComponent}</DialogContent>}
            {children}
        </StyledDialog>
    );
};

export default SlideInPopup;
